import React from 'react';

function WeekDayModal({ value, onChange, onClose }) {
  const weekDays = [
    { id: 'понедельник', name: 'Понедельник', short: 'Пн' },
    { id: 'вторник', name: 'Вторник', short: 'Вт' },
    { id: 'среда', name: 'Среда', short: 'Ср' },
    { id: 'четверг', name: 'Четверг', short: 'Чт' },
    { id: 'пятница', name: 'Пятница', short: 'Пт' },
    { id: 'суббота', name: 'Суббота', short: 'Сб' },
    { id: 'воскресенье', name: 'Воскресенье', short: 'Вс' }
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Выберите день недели</h3>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="weekday-selector">
          {weekDays.map(day => (
            <button
              key={day.id}
              className={`weekday-button ${value === day.id ? 'selected' : ''}`}
              onClick={() => {
                onChange(day.id);
                onClose();
              }}
            >
              {day.short}
            </button>
          ))}
        </div>
        <button className="close-button" onClick={onClose}>
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default WeekDayModal; 