import React, { useState, useEffect } from 'react';
import { formatDate, parseDate } from '../utils/dateUtils';

function DateInput({ value, onChange, openPicker, placeholder = "ДД.ММ.ГГГГ", showReset = false }) {
  const [inputValue, setInputValue] = useState(formatDate(value || ''));

  useEffect(() => {
    setInputValue(formatDate(value || ''));
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    const parsedDate = parseDate(newValue);
    if (parsedDate) {
      onChange(parsedDate);
    }
  };

  return (
    <div className="date-input-container">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="date-input"
      />
      <button
        className="select-date-button"
        onClick={openPicker}
      >
        Выбрать
      </button>
      {showReset && value && (
        <button
          className="reset-date-button"
          onClick={() => {
            setInputValue('');
            onChange('');
          }}
          title="Сбросить дату"
        >
          ✕
        </button>
      )}
    </div>
  );
}

export default DateInput; 