import React from 'react';

function Editor(props) {
  const initialScenario = localStorage.getItem("scenario")
  
  return <textarea 
    onChange={props.onChange} 
    ref={props.ref_}
    placeholder='Введите сценарий' 
    defaultValue={initialScenario}
    autoFocus
  />
}

export default Editor; 