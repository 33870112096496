import React, { useState, useEffect } from 'react';

function DatePicker({ value, onChange, onClose }) {
  const today = new Date();
  const initialDate = value ? new Date(value) : today;
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [currentMonth, setCurrentMonth] = useState(initialDate.getMonth());
  const [currentYear, setCurrentYear] = useState(initialDate.getFullYear());
  const [showMonthSelect, setShowMonthSelect] = useState(false);
  const [showYearInput, setShowYearInput] = useState(false);

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);
      setSelectedDate(newDate);
      setCurrentMonth(newDate.getMonth());
      setCurrentYear(newDate.getFullYear());
    }
  }, [value]);

  const handleDateClick = (date) => {
    const newDate = new Date(currentYear, currentMonth, date);
    const formattedDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
    onChange(formattedDate);
    onClose();
  };

  const handleMonthSelect = (month) => {
    setCurrentMonth(month);
    setShowMonthSelect(false);
  };  

  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value);
    if (newYear >= 1900 && newYear <= 2100) {
      setCurrentYear(newYear);
      setShowYearInput(false);
    }
  };

  const handleYearClick = () => {
    setShowYearInput(true);
  };

  const handleMonthClick = () => {
    setShowMonthSelect(true);
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const day = new Date(year, month, 1).getDay();
    return day === 0 ? 6 : day - 1;
  };

  const isToday = (date) => {
    const today = new Date();
    return date === today.getDate() &&
      currentMonth === today.getMonth() &&
      currentYear === today.getFullYear();
  };

  const isSelected = (date) => {
    if (!value) return false;
    const selectedDate = new Date(value);
    return date === selectedDate.getDate() &&
      currentMonth === selectedDate.getMonth() &&
      currentYear === selectedDate.getFullYear();
  };

  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const firstDay = getFirstDayOfMonth(currentYear, currentMonth);
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const emptyDays = Array(firstDay).fill(null);

  const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Выберите дату</h3>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="calendar">
          <div className="calendar-header">
            <button className="calendar-nav-button" onClick={handlePrevMonth}>&lt;</button>
            <div className="calendar-title">
              {showMonthSelect ? (
                <div className="month-select">
                  {months.map((month, index) => (
                    <button
                      key={month}
                      className={`month-option ${currentMonth === index ? 'selected' : ''}`}
                      onClick={() => handleMonthSelect(index)}
                    >
                      {month}
                    </button>
                  ))}
                </div>
              ) : showYearInput ? (
                <input
                  type="number"
                  value={currentYear}
                  onChange={handleYearChange}
                  min="1900"
                  max="2100"
                  className="year-input"
                  autoFocus
                />
              ) : (
                <>
                  <button 
                    className="month-year-button"
                    onClick={() => setShowMonthSelect(true)}
                  >
                    {months[currentMonth]}
                  </button>
                  <button 
                    className="month-year-button"
                    onClick={() => setShowYearInput(true)}
                  >
                    {currentYear}
                  </button>
                </>
              )}
            </div>
            <button className="calendar-nav-button" onClick={handleNextMonth}>&gt;</button>
          </div>
          <div className="calendar-weekdays">
            {weekDays.map(day => (
              <div key={day} className="calendar-weekday">{day}</div>
            ))}
          </div>
          <div className="calendar-days">
            {emptyDays.map((_, index) => (
              <div key={`empty-${index}`} className="calendar-day empty"></div>
            ))}
            {days.map((day, index) => (
              <button
                key={index}
                className={`calendar-day ${isToday(day) ? 'today' : ''} ${
                  isSelected(day) ? 'selected' : ''
                }`}
                onClick={() => handleDateClick(day)}
              >
                {day}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatePicker; 