import { formatDateYYYYMMDD } from './dateUtils';

const getWeekDayInDative = (day) => {
  const days = {
    'понедельник': 'понедельникам',
    'вторник': 'вторникам',
    'среда': 'средам',
    'четверг': 'четвергам',
    'пятница': 'пятницам',
    'суббота': 'субботам',
    'воскресенье': 'воскресеньям'
  };
  return days[day] || day;
};

export const tableToText = (rows, settings) => {
  const lines = [];
  
  // Добавляем строку с инфляцией
  lines.push(`инфляция ${settings.inflation}%`);
  if (settings.startDate || settings.endDate) {
    let line = '';  
    line = (`с ${formatDateYYYYMMDD(settings.startDate ? settings.startDate : Date.now())} по ${formatDateYYYYMMDD(settings.endDate ? settings.endDate : Date.now() + 2 * 31 * 24 * 60 * 60 * 1000)}`);
    lines.push(line);
  }
  // Обрабатываем каждую строку таблицы
  rows.forEach(row => {
    let line = '';
    if (!row.amount) return;
    // Формируем начало строки в зависимости от периодичности
    switch (row.periodicity) {
      case 'один раз':
        line = `${formatDateYYYYMMDD(row.oneTimeDate)}`;
        break;
      case 'каждый день':
        line = 'каждый день';
        break;
      case 'каждую неделю':
        line = `каждую неделю по ${getWeekDayInDative(row.weeklyDay)}`;
        break;
      case 'каждый месяц':
        line = `каждый месяц ${row.monthlyDay} числа`;
        break;
      default:
        return; // Пропускаем строки без периодичности
    }
    
    // Добавляем сумму
    if (row.amount) {
      line += ` ${row.amount} `;
    }
    
    // Добавляем флаг "без инфляции"
    if (row.withoutInflation) {
      line += ' без инфляции';
    }
    
    // Добавляем описание
    if (row.description) {
      line += ` (${row.description})`;
    }
    
    lines.push(line);
  });
  
  return lines.join('\n');
}; 