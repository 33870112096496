import logo from './logo.svg';
import logo_big from './logo_big.png'
import './App.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement} from "chart.js";
import { useState, useRef } from 'react';

// Импортируем компоненты

import Instruction from './components/Instruction';
import Editor from './components/Editor';
import Result from './components/Result';
import TabPanel from './components/TabPanel';
import TableEditor from './components/TableEditor';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

let INITIAL_CHART_DATA = {
  labels: [],
  datasets:[],
}

try {
  INITIAL_CHART_DATA = JSON.parse(localStorage.getItem('chart')) || INITIAL_CHART_DATA 
} catch {
  console.error('can not parse chart data')
}

function prepareData(data) {
  return {
    labels: data["dates"],
    datasets: [
      {
        id: 1,
        label: 'Деньги на руках',
        data: data["money"],
        borderColor: 'rgb(30, 99, 132)',
        backgroundColor: 'rgba(30, 99, 132, 0.5)',
        radius: 0
      },
      {
        id: 2,
        label: 'Сбережения',
        data: data["savings"],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 240, 132, 0.5)',
        radius: 0
      },
    ],
  }
}

function App() {
  const [data, setData] = useState(INITIAL_CHART_DATA)
  const [activeTab, setActiveTab] = useState(0)
  const ref = useRef()

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  }   
  
  let initiateNewChart = value => {
    //const BACKEND_URI = 'http://localhost:5000/evaluate'
    const BACKEND_URI = '/evaluate'
    fetch(BACKEND_URI, {
      method: "POST",
      body: JSON.stringify({ 
        text: value
      }),
      headers: {'Content-Type': 'application/json'}
    }
    ).then(response=>response.json()).then(
      function(data) {
        localStorage.setItem("scenario", value)
        const chartData = prepareData(data)
        localStorage.setItem('chart', JSON.stringify(chartData))
        return setData(chartData)
      }
    )
  }

  let handleChange = (event) => {
    event.persist();
    initiateNewChart(event.target.value)
  }

  let optimisedHandleChange = debounce(handleChange,500); 

  return (
    <div className="App">
      <div className="header">
        <img src={logo_big} height={80}/>
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => setActiveTab(0)}
          >
            Ввод данных таблицей
          </button>
          <button 
            className={`tab ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            Ввод данных
          </button>
          <button 
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => setActiveTab(2)}
          >
            Инструкции
          </button>
        </div>
      </div>

      <TabPanel value={activeTab} index={0}>
        <TableEditor newData={initiateNewChart} />
        <div className='Result'> <Result data={data}/> </div>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <div><Instruction change={value=>{ref.current.value = ref.current.value + '\n' + value; initiateNewChart(ref.current.value)}}/></div>
        <div><Editor setData={setData} onChange={optimisedHandleChange} ref_={ref}/></div>
        <div className='Result'> <Result data={data}/> </div>
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        
      </TabPanel>

      <div className='Footer'> пишите мне на eugene.katsevman@gmail.com или в <a href="https://t.me/eugene_mk"> телеграм </a></div>
    </div>
  ); 
}

export default App;
