import React from 'react';

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ display: value === index ? 'block' : 'none' }}
    >
      {value === index && children}
    </div>
  );
}

export default TabPanel; 