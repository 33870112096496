export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}.${month}.${year}`;
};

export const formatDateYYYYMMDD = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  return `${year}.${month}.${day}`;
};

export const parseDate = (dateString) => {
  if (!dateString) return '';
  const [day, month, year] = dateString.split('.');
  if (!day || !month || !year) return '';
  
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) return '';
  
  return date.toISOString().split('T')[0];
}; 