import React from 'react';
import Button from './Button';

function Instruction(props) {
  return <div>
    <Button change={props.change}>с 2023.05.23 по 2024.05.23</Button>
    <Button change={props.change}>инфляция 15%</Button>
    <Button change={props.change}>откладывать 0.5% от денег</Button>
    <Button change={props.change}>откладывать 1% от прибыли</Button>

    <Button change={props.change}>каждый день -400 на еду</Button>
    <Button change={props.change}>каждый месяц 10 числа 7000 зарплата 1 без инфляции</Button>
    <Button change={props.change}>каждый месяц 25 числа -7000 коммуналка</Button>
    <Button change={props.change}>каждую неделю по понедельникам -1500 вождение кат Б</Button>

    <Button change={props.change}>2023.07.23 -12000 читалка</Button>
  </div>
}

export default Instruction; 