import React, { useState, useEffect } from 'react';
import WeekDayModal from './WeekDayModal';
import DateGrid from './DateGrid';
import DatePicker from './DatePicker';
import TableSettings from './TableSettings';
import DateInput from './DateInput';
import { formatDate, parseDate } from '../utils/dateUtils';
import { tableToText } from '../utils/tableUtils';
import './TableEditor.css';

function TableEditor({newData}) {
  const [rows, setRows] = useState(() => {
    const savedRows = localStorage.getItem('tableRows');
    return savedRows ? JSON.parse(savedRows) : [];
  });
  const [showWeekDayModal, setShowWeekDayModal] = useState(false);
  const [showDateGrid, setShowDateGrid] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activeRowId, setActiveRowId] = useState(null);
  const [settings, setSettings] = useState(() => {
    const savedSettings = localStorage.getItem('tableSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      inflation: 5,
      startDate: '',
      endDate: ''
    };
  });

  useEffect(() => {
    localStorage.setItem('tableRows', JSON.stringify(rows));
    localStorage.setItem('tableSettings', JSON.stringify(settings));
    
    // Преобразуем данные таблицы в текст
    const text = tableToText(rows, settings);
    // Обновляем значение во второй вкладке
    const textArea = document.querySelector('.second-tab textarea');
    if (textArea) {
      textArea.value = text;
    }
    console.log(text);
    if (newData) {
      console.log('newData');
      newData(text);
    } 
  }, [rows, settings]);

  const handleSettingsChange = (key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const addRow = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const newRow = {
      id: Date.now(),
      periodicity: 'каждый месяц',
      oneTimeDate: '',
      monthlyDay: '1',
      weeklyDay: '',
      amount: '',
      withoutInflation: false,
      description: ''
    };
    setRows([...rows, newRow]);
  };

  const removeRow = (rowId) => {
    setRows(rows.filter(row => row.id !== rowId));
  };

  const updateCell = (rowId, field, value) => {
    setRows(prevRows => {
      return prevRows.map(row => {
        if (row.id === rowId) {
          return { ...row, [field]: value };
        }
        return row;
      });
    });
  };

  const handleWeekDaySelect = (day) => {
    if (activeRowId !== null) {
      updateCell(activeRowId, 'weeklyDay', day);
      setShowWeekDayModal(false);
      setActiveRowId(null);
    }
  };

  const handleDateSelect = (day) => {
    if (activeRowId !== null) {
      updateCell(activeRowId, 'monthlyDay', day);
      setShowDateGrid(false);
      setActiveRowId(null);
    }
  };

  const handleInputChange = (e, rowId) => {
    const newValue = e.target.value;
    updateCell(rowId, 'monthlyDay', newValue);
  };

  const openDateGrid = (rowId) => {
    setActiveRowId(rowId);
    setShowDateGrid(true);
  };

  const openWeekDayModal = (rowId) => {
    setActiveRowId(rowId);
    setShowWeekDayModal(true);
  };

  const openDatePicker = (rowId) => {
    setActiveRowId(rowId);
    setShowDatePicker(true);
  };

  const renderPeriodInput = (rowId, value) => {
    const handlePeriodChange = (e) => {
      const newValue = e.target.value;
      updateCell(rowId, 'periodicity', newValue);
    };

    return (
      <div className="period-input-container">
        <select
          value={value}
          onChange={handlePeriodChange}
          className="period-select"
        >
          <option value="">Выберите периодичность</option>
          <option value="один раз">один раз</option>
          <option value="каждый день">каждый день</option>
          <option value="каждую неделю">каждую неделю</option>
          <option value="каждый месяц">каждый месяц</option>
        </select>
        {value === 'один раз' && (
          <DateInput
            value={rows.find(r => r.id === rowId)?.oneTimeDate}
            onChange={(value) => updateCell(rowId, 'oneTimeDate', value)}
            openPicker={() => openDatePicker(rowId)}
          />
        )}
        {value === 'каждый месяц' && (
          <div className="date-input-container">
            <input
              type="text"
              value={rows.find(r => r.id === rowId)?.monthlyDay || ''}
              onChange={(e) => handleInputChange(e, rowId)}
              placeholder="Введите число от 1 до 31"
              className={`date-input ${rows.find(r => r.id === rowId)?.monthlyDay && (parseInt(rows.find(r => r.id === rowId)?.monthlyDay) < 1 || parseInt(rows.find(r => r.id === rowId)?.monthlyDay) > 31) ? 'invalid' : ''}`}
            />
            <button 
              className="select-date-button"
              onClick={() => openDateGrid(rowId)}
            >
              Выбрать
            </button>
          </div>
        )}
        {value === 'каждую неделю' && (
          <div className="date-input-container">
            <input
              type="text"
              value={rows.find(r => r.id === rowId)?.weeklyDay || ''}
              readOnly
              placeholder="Выберите день недели"
              className="date-input"
              onClick={() => openWeekDayModal(rowId)}
            />
            <button 
              className="select-date-button"
              onClick={() => openWeekDayModal(rowId)}
            >
              Выбрать
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="table-container">
      <TableSettings settings={settings} onChange={handleSettingsChange} />
      <table className="data-table">
        <thead>
          <tr>
            <th>Периодичность</th>
            <th>Сумма</th>
            <th>Без инфляции</th>
            <th>Описание</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.id}>
              <td>{renderPeriodInput(row.id, row.periodicity)}</td>
              <td>
                <input
                  type="number"
                  value={row.amount}
                  onChange={(e) => updateCell(row.id, 'amount', e.target.value)}
                  className="amount-input"
                  placeholder="Введите сумму"
                />
              </td>
              <td className="checkbox-container">
                <input
                  type="checkbox"
                  checked={row.withoutInflation}
                  onChange={(e) => updateCell(row.id, 'withoutInflation', e.target.checked)}
                  className="inflation-checkbox"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.description}
                  onChange={(e) => updateCell(row.id, 'description', e.target.value)}
                  className="description-input"
                  placeholder="Введите описание"
                />
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => removeRow(row.id)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button add-row" onClick={addRow}>Добавить строку</button>

      {showDateGrid && activeRowId !== null && (
        <DateGrid
          value={rows.find(row => row.id === activeRowId)?.monthlyDay || ''}
          onChange={handleDateSelect}
          onClose={() => {
            setShowDateGrid(false);
            setActiveRowId(null);
          }}
        />
      )}

      {showWeekDayModal && activeRowId !== null && (
        <WeekDayModal
          value={rows.find(row => row.id === activeRowId)?.weeklyDay || ''}
          onChange={handleWeekDaySelect}
          onClose={() => {
            setShowWeekDayModal(false);
            setActiveRowId(null);
          }}
        />
      )}

      {showDatePicker && activeRowId !== null && (
        <DatePicker
          value={rows.find(row => row.id === activeRowId)?.oneTimeDate || ''}
          onChange={(value) => {
            updateCell(activeRowId, 'oneTimeDate', value);
            setShowDatePicker(false);
            setActiveRowId(null);
          }}
          onClose={() => {
            setShowDatePicker(false);
            setActiveRowId(null);
          }}
        />
      )}
    </div>
  );
}

export default TableEditor; 