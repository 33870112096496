import React, { useState } from 'react';
import DatePicker from './DatePicker';
import DateInput from './DateInput';
import './TableSettings.css';

function TableSettings({ settings, onChange }) {
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  return (
    <div className="table-settings">
      <div className="settings-group">
        <label>Инфляция (%):</label>
        <input
          type="number"
          value={settings.inflation}
          onChange={(e) => onChange('inflation', e.target.value)}
          min="0"
          max="100"
          step="0.1"
          className="inflation-input"
        />

        <label>Начальная дата:</label>
        <DateInput
          value={settings.startDate}
          onChange={(value) => onChange('startDate', value)}
          openPicker={() => setShowStartDatePicker(true)}
          showReset={true}
        />
        
        <label>Конечная дата:</label>
        <DateInput
          value={settings.endDate}
          onChange={(value) => onChange('endDate', value)}
          openPicker={() => setShowEndDatePicker(true)}
          showReset={true}
        />
      </div>

      {showStartDatePicker && (
        <DatePicker
          value={settings.startDate}
          onChange={(value) => {
            onChange('startDate', value);
            setShowStartDatePicker(false);
          }}
          onClose={() => setShowStartDatePicker(false)}
        />
      )}

      {showEndDatePicker && (
        <DatePicker
          value={settings.endDate}
          onChange={(value) => {
            onChange('endDate', value);
            setShowEndDatePicker(false);
          }}
          onClose={() => setShowEndDatePicker(false)}
        />
      )}
    </div>
  );
}

export default TableSettings; 