import React from 'react';

function DateGrid({ value, onChange, onClose }) {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const selectedDay = value ? parseInt(value) : null;

  const handleDayClick = (day) => {
    onChange(day);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Выберите число месяца</h3>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="date-grid">
          {days.map(day => (
            <button
              key={day}
              className={`date-grid-item ${selectedDay === day ? 'selected' : ''}`}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DateGrid; 