import React from 'react';
import { Line } from "react-chartjs-2";

function Result(props) {
  const options = {maintainAspectRatio: false}
  return <Line  
    datasetIdKey='id'
    data={props.data}
    options={options}
  />
}

export default Result; 